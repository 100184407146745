import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'
import iconBanner from '../images/fornecedores/icons/iconFornecedores.svg'

import './styles/fornecedores.scss'
import RenderForm from 'gatsby-plugin-cloudofertas'

const customCheckbox = (field, register, errors) => {
  console.log('field', field)

  return (
    <>
      <label className="select-box">
        <input type="checkbox"
          {...register(field.name, {
            required: field.required
          })} />
        <span className="checkmark"></span>
        <span className='ml-lg-3 link-style' dangerouslySetInnerHTML={{ __html: field.label }}></span>
      </label>
    </>
  )
}

const customFields = {
  politicatermos: customCheckbox
}

const SejaFornecedor = ({ data }) => {
  console.log('data seja um fornecedor:', data)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <>
      <Pagina pagina={data.pagina} />
      <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
      <div className='bg-ri py-5'>
        {data.cloudOfertasForm.formTypeField && submitSuccess === false &&
          <RenderForm
            formData={data.cloudOfertasForm}
            setSuccessSubmit={setSubmitSuccess}
            placeholder={false}
            customFields={customFields}
            containerStyle='container'
            rowStyle='row'
            inputStyle='custom-form-ri col-12'
            btnContainerStyle='custom-form-ri col'
            btnStyle='btn btn-block'
            btnName='Enviar'
          />
        }
        {submitSuccess &&
          <div className='container mt-0'>
            <div className="text-center font-Spinnaker">
              <p>Obrigado por entrar em contato com a gente. Responderemos em até 48h.</p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-flex d-none"
      >
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100 position-relative">
            <div className='vertical-line position-absolute'></div>
            <div className='conteudo-banner d-flex'>
              <div className="col-2 p-0">
                <img src={iconBanner} className='w-90' />
              </div>
              <div className="col-5 p-0">
                <h1 className="text-white">Seja um Fornecedor</h1>
                <p className=" text-banner text-white mt-3">Estamos comprometidos com a parceria transparente e eficaz com nossos fornecedores. Agradecemos por contribuir cada vez mais para o nosso sucesso mútuo.</p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImg>
      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-none d-flex h-50"
      >
        <div className="container h-100 position-relative d-flex align-items-center">
          <div className='vertical-line position-absolute'></div>
          <div>
            <div className='d-flex align-items-center'>
              <img src={iconBanner} className='w-20 mr-3 ml-4 mt-2' />
            </div>
            <div className='mt-3 ml-4'>
              <h1 className="text-white">Fornecedores</h1>
            </div>
            <div className='mt-2 ml-4'>
              <p className="text-white w-55">Estamos comprometidos com a parceria transparente e eficaz com nossos fornecedores. Agradecemos por contribuir cada vez mais para o nosso sucesso mútuo.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

export default SejaFornecedor

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/seja-um-fornecedor/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "fornecedores/bannerFornecedores.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  cloudOfertasForm(name: {eq: "SEJA_UM_FORNECEDOR"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
      formLov {
        formLovData {
          id
          value
          createdDate
        }
      }
    }
  }
}
`
